import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import { Container, Row, Col } from 'react-grid-system'
import componentStyles from './PageHeader.module.scss'
import { FaChevronRight } from 'react-icons/fa'

const PageHeader = ({ subheader, header, breadcrumb, ...props }) => {
  return (
    <header className={componentStyles.header}>
      <div className={componentStyles.headerContent}>
        <Container>
          <Row>
            {subheader && (
              <Col md={12}>
                <span className={componentStyles.subheader}>{subheader}</span>
              </Col>
            )}
            {breadcrumb && (
              <Col md={12}>
                <Link
                  to={breadcrumb.path}
                  className={componentStyles.subheader}
                >
                  {breadcrumb.text}
                </Link>
                <FaChevronRight className={componentStyles.icon} />
              </Col>
            )}
            <Col md={12}>
              <h1>{header}</h1>
            </Col>
          </Row>
        </Container>
      </div>
    </header>
  )
}

export default PageHeader
