import React, { useState } from 'react'
import { Link, useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { Container, Row, Col } from 'react-grid-system'
import Classnames from 'classnames'
import componentStyles from './Nav.module.scss'
import Logo from '../../svgs/hcraLogoColor.svg'
import FRLogo from '../../svgs/hcraLogoColor_fr.svg'
import { FaSignInAlt } from 'react-icons/fa'
import { IoMdMegaphone } from 'react-icons/io'

import NavDropdown from '../NavDropdown/NavDropdown'
import TranslationToggle from './TranslationToggle'
import NavSecondTier from './NavSecondTier'
// import NavSecondTierBuilder from './NavSecondTierBuilder';
// import NavSecondTierBuyer from './NavSecondTierBuyer';

const NavMobile = ({ view, ...props }) => {
  const intl = useIntl()
  const [activeNavItem, setNavItem] = useState(null)
  const [drawerOpen, toggleDrawer] = useState(false)

  const toggleNavItem = (id) => {
    if (id !== activeNavItem) setNavItem(id)
    else if (id === activeNavItem) setNavItem(null)
  }

  return (
    <nav aria-label='HCRA Main Navigation' className={componentStyles.nav}>
      <Container>
        <Row className={componentStyles.row}>
          <Col lg={3}>
            <Link to='/'>
              {intl.locale === 'en' ? (
                <Logo
                  className={componentStyles.logo}
                  alt='Home Construction Regulatory Authority Logotype'
                />
              ) : (
                <FRLogo
                  className={componentStyles.logo}
                  alt='Office de Reglementation de la Construction des Logements'
                />
              )}
            </Link>
          </Col>
        </Row>

        {/* hamburger menu */}
        <button
          id='topnav-menuBtn'
          className={componentStyles.hamburger}
          aria-haspopup='true'
          aria-roledescription='Open the menu'
          aria-label='Open the menu'
          aria-expanded={drawerOpen}
          onClick={() => toggleDrawer(!drawerOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <Row>
          <Col md={12}>
            <nav
              aria-label='Main Navigation'
              className={
                drawerOpen
                  ? Classnames(componentStyles.mainNav, componentStyles.active)
                  : componentStyles.mainNav
              }
            >
              <ul role='presentation' className={componentStyles.mainNavList}>
                <li role='none' className={componentStyles.standalone}>
                  <Link to='/' role='menuitem'>
                    <FormattedMessage id='nav.home' />
                  </Link>
                </li>

                <li role='none' className={componentStyles.standalone}>
                  <a
                    role='menuitem'
                    href='https://builderportal.hcraontario.ca'
                  >
                    <FormattedMessage id='nav.portalLink' />
                    <FaSignInAlt className={componentStyles.icon} />
                  </a>
                </li>

                <li role='none' className={componentStyles.standalone}>
                  <Link to='/licensing-compliance/file-a-complaint/'>
                    <FormattedMessage id='nav.fileComplaint' />
                    <IoMdMegaphone className={componentStyles.icon} />
                  </Link>
                </li>

                <li role='none' className={componentStyles.standalone}>
                  <Link to='/about/'>
                    <FormattedMessage id='nav.about' />
                  </Link>
                </li>

                <NavDropdown
                  id='nav_licensing'
                  heading={<FormattedMessage id='nav.licensingComp' />}
                  activeItem={activeNavItem}
                  onClick={() => toggleNavItem('nav_licensing')}
                >
                  <ul
                    role='presentation'
                    aria-label={intl.formatMessage({ id: 'nav.licensingComp' })}
                  >
                    <li role='none'>
                      <Link
                        to='/licensing-compliance/licensing/'
                        role='menuitem'
                      >
                        <FormattedMessage id='nav.licensing' />
                      </Link>
                    </li>

                    <li role='none'>
                      <Link
                        to='/licensing-compliance/complaints-process/'
                        role='menuitem'
                      >
                        <FormattedMessage id='nav.complaintsProcess' />
                      </Link>
                    </li>

                    <li role='none'>
                      <Link
                        to='/licensing-compliance/file-a-complaint/'
                        role='menuitem'
                      >
                        <FormattedMessage id='nav.fileComplaint' />
                      </Link>
                    </li>

                    <li role='none'>
                      <Link to='/licensing-compliance/directives-advisories/'>
                        <FormattedMessage id='nav.directives' />
                      </Link>
                    </li>

                    <li role='none'>
                      <Link to='/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'>
                        <FormattedMessage id='nav.becomeBuilder' />
                      </Link>
                    </li>

                    <li role='none'>
                      <Link to='/licensing-compliance/regulatory-activites-enforcement/'>
                        <FormattedMessage id='nav.regActivites' />
                      </Link>
                    </li>
                  </ul>
                </NavDropdown>

                <li role='none' className={componentStyles.standalone}>
                  <Link to='/news-and-events/' role='menuitem'>
                    <FormattedMessage id='nav.news' />
                  </Link>
                </li>

                <NavSecondTier />

                <li role='none' className={componentStyles.standalone}>
                  <TranslationToggle />
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
    </nav>
  )
}

export default NavMobile
