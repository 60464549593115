import React, { useState } from 'react'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import { Container, Row, Col } from 'react-grid-system'
import { useMediaQuery } from 'react-responsive'
import componentStyles from './Nav.module.scss'
import NavDropdown from '../NavDropdown/NavDropdown'

import HomeIcon from '../../svgs/houseBlue.svg'
import CraneBlue from '../../svgs/craneHome-blue.svg'
import OBDlogo from '../../svgs/OBDlogo-blue.svg'

const NavSecondTierBuilder = () => {
  const intl = useIntl()
  const [activeNavItem, setNavItem] = useState(null)

  const toggleNavItem = (id) => {
    if (id !== activeNavItem) setNavItem(id)
    else if (id === activeNavItem) setNavItem(null)
  }

  const isScreenSm = useMediaQuery({ maxWidth: 1200 })

  return (
    <section className={componentStyles.secondTier}>
      <Container
        style={{ width: `100%` }}
        className={componentStyles.gridContainer}
      >
        <Row>
          <Col md={12}>
            <div className={componentStyles.secondTierContainer}>
              <div className={componentStyles.viewToggle}>
                <div>
                  <Link
                    to='/new-home-buyer/dashboard/'
                    aria-label={intl.formatMessage({
                      id: 'buyerNav.dashboard'
                    })}
                  >
                    <HomeIcon className={componentStyles.svg} />
                  </Link>
                </div>

                <div>
                  <b className='uppercase'>
                    <FormattedMessage id='nav.buyerDashB' />
                  </b>

                  {!isScreenSm && (
                    <ul role='presentation'>
                      <NavDropdown
                        id='builder_switchView'
                        heading={<FormattedMessage id='nav.switchView' />}
                        activeItem={activeNavItem}
                        size='sm'
                        onClick={() => toggleNavItem('builder_switchView')}
                      >
                        <ul role='presentation' aria-label='Toggle User View'>
                          <li role='none'>
                            <Link
                              to='/builder-vendor/dashboard/'
                              role='menuitem'
                            >
                              <CraneBlue />
                              <div>
                                <span className={componentStyles.sm}>
                                  <FormattedMessage id='nav.builderDashA' />
                                </span>
                                <br />
                                <b>
                                  <FormattedMessage id='nav.builderDashB' />
                                </b>
                              </div>
                            </Link>
                          </li>

                          <li role='none'>
                            <a
                              href='https://obd.hcraontario.ca'
                              role='menuitem'
                            >
                              <OBDlogo />
                              <div>
                                <span className={componentStyles.sm}>
                                  <FormattedMessage id='nav.obdA' />
                                </span>
                                <br />
                                <b>
                                  <FormattedMessage id='nav.obdB' />
                                </b>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </NavDropdown>
                    </ul>
                  )}
                </div>
              </div>

              <div>
                <ul role='presentation'>
                  <li role='none' className={componentStyles.standalone}>
                    <Link
                      to='/new-home-buyer/steps-in-home-buyers-journey/step-1/'
                      role='menuitem'
                    >
                      <FormattedMessage id='buyerNav.steps' />
                    </Link>
                  </li>

                  <li role='none' className={componentStyles.standalone}>
                    <Link
                      to='/licensing-compliance/complaints-process/'
                      role='menuitem'
                    >
                      <FormattedMessage id='buyerNav.complaints' />
                    </Link>
                  </li>

                  <NavDropdown
                    id='builder_resources'
                    heading={<FormattedMessage id='nav.links' />}
                    activeItem={activeNavItem}
                    onClick={() => toggleNavItem('builder_resources')}
                    lastItem={true}
                  >
                    <ul
                      role='presentation'
                      aria-label={intl.formatMessage({ id: 'nav.links' })}
                    >
                      <li role='none'>
                        <Link
                          to='/new-home-buyer/before-buying-a-preconstruction-condo/'
                          role='menuitem'
                        >
                          <FormattedMessage id='buyerNav.preConCondo' />
                        </Link>
                      </li>
                      <li role='none'>
                        <Link
                          to='/new-home-buyer/expectations-for-your-builder/'
                          role='menuitem'
                        >
                          <FormattedMessage id='buyerNav.expectations' />
                        </Link>
                      </li>
                      <li role='none'>
                        <Link
                          to='/new-home-buyer/working-with-your-builder/'
                          role='menuitem'
                        >
                          <FormattedMessage id='buyerNav.workingWith' />
                        </Link>
                      </li>
                    </ul>
                  </NavDropdown>
                </ul>

                {isScreenSm && (
                  <>
                    <Link
                      to='/builder-vendor/dashboard/'
                      className={componentStyles.secondTierLink}
                    >
                      <CraneBlue className={componentStyles.svg} />
                      <div>
                        <span>
                          <FormattedMessage id='nav.builderDashA' />
                        </span>
                        <br />
                        <b>
                          <FormattedMessage id='nav.builderDashB' />
                        </b>
                      </div>
                    </Link>

                    <a
                      href='https://obd.hcraontario.ca'
                      className={componentStyles.secondTierLink}
                    >
                      <OBDlogo className={componentStyles.svg} />
                      <div>
                        <span>
                          <FormattedMessage id='nav.obdA' />
                        </span>
                        <br />
                        <b>
                          <FormattedMessage id='nav.obdB' />
                        </b>
                      </div>
                    </a>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default NavSecondTierBuilder
