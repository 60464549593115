import React from 'react'
import { useIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
import { Container, Row, Col } from 'react-grid-system'
import componentStyles from './PageFooter.module.scss'
import {
  FaAngleUp,
  FaEnvelope,
  FaPhone,
  FaClock,
  FaMapMarkerAlt,
  FaQuestion,
  FaTwitter,
  FaFacebookSquare,
  FaLinkedin,
  FaYoutube,
  FaEnvelopeOpenText
} from 'react-icons/fa'
import Button from '../Button/Button'
import BlogIcon from '../../svgs/blogIcon.svg'

const PageFooter = () => {
  const intl = useIntl()
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  return (
    <footer className={componentStyles.footer}>
      <div className={componentStyles.footerTop}>
        <Container>
          <Row>
            <Col md={6} className='pageRow'>
              <h2 className={componentStyles.underline}>
                <FormattedMessage id='nav.contact' />
              </h2>
              <div className={componentStyles.contact}>
                <div>
                  <div className={componentStyles.contactItem}>
                    <FaEnvelope className={componentStyles.icon} />
                    <a href='mailto:info@hcraontario.ca'>info@hcraontario.ca</a>
                  </div>

                  <div className={componentStyles.contactItem}>
                    <FaPhone className={componentStyles.icon} />
                    <div>
                      <a href='tel:416-487-4272'>(416) 487-HCRA (4272)</a>
                      <br />
                      <span>
                        <FormattedMessage id='footer.tollFree' />
                      </span>{' '}
                      <a href='tel:1-800-582-7994'>1-800-582-7994</a>
                    </div>
                  </div>

                  <div className={componentStyles.contactItem}>
                    <FaQuestion className={componentStyles.icon} />
                    <div>
                      <Link to='/builder-vendor/faq/'>
                        <FormattedMessage id='footer.faq' />
                      </Link>
                    </div>
                  </div>
                </div>

                <div>
                  <div className={componentStyles.contactItem}>
                    <FaMapMarkerAlt className={componentStyles.icon} />
                    <span>
                      <FormattedMessage id='footer.addressA' /> <br />
                      <FormattedMessage id='footer.addressB' /> <br />
                      <a
                        href='https://goo.gl/maps/hs4uCokxVXZnmhKM8'
                        aria-label={intl.formatMessage({ id: 'footer.map' })}
                      >
                        <FormattedMessage id='footer.map' />
                      </a>
                    </span>
                  </div>

                  <div className={componentStyles.contactItem}>
                    <FaClock className={componentStyles.icon} />
                    <span>
                      <FormattedMessage id='footer.hours' />{' '}
                      <FormattedMessage id='footer.hoursB' />
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <p className='footnote'>
                  <FormattedMessage id='footer.officeAccess' />
                </p>
              </div>

              {/* call centre notice */}
              {/* <div>
                <p className='footnote'> */}
              {/* <FormattedMessage id='footer.callCentreA' />{' '}
                  <FormattedMessage id='footer.callCentreB' />
                  <br />
                  <FormattedMessage id='footer.callCentreC' /> */}
              {/* <FormattedMessage id='footer.officeClosure' /> */}
              {/* </p>
              </div> */}
            </Col>

            <Col md={6} className='pageRow'>
              <div>
                <h2 className={componentStyles.underline}>
                  <FormattedMessage id='footer.followUs' />
                </h2>
                <ul>
                  <li>
                    <a
                      aria-label='HCRA Facebook page'
                      href='https://www.facebook.com/hcraontario'
                    >
                      <span className={componentStyles.icon}>
                        <FaFacebookSquare />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label='HCRA Twitter page'
                      href='https://twitter.com/hcraontario'
                    >
                      <span className={componentStyles.icon}>
                        <FaTwitter />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label='HCRA LinkedIn page'
                      href='https://www.linkedin.com/company/home-construction-regulatory-authority-hcra'
                    >
                      <span className={componentStyles.icon}>
                        <FaLinkedin />
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label='HCRA Youtube page'
                      href='https://www.youtube.com/channel/UCFkWbZv351PeJ9Z_DoeXngw?reload=9'
                    >
                      <span className={componentStyles.icon}>
                        <FaYoutube />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                {/* <h2 className={componentStyles.underline}>
                  <FormattedMessage id='newsletter.headerA' />
                </h2> */}
                <div className='flexContainer'>
                  <Button
                    id='newsletterLink'
                    linkPath='/new-home-buyer/newsletter/'
                    sm
                  >
                    <FaEnvelopeOpenText
                      style={{
                        margin: `0 .5rem 0  0`,
                        maxWidth: `none`,
                        fontSize: `1.25rem`,
                        fontWeight: `bold`
                      }}
                    />
                    <span>
                      {/* <FormattedMessage id='newsletter.subscribe' /> */}
                      <FormattedMessage id='newsletter.headerA' />
                    </span>
                  </Button>

                  <Button id='blogLink' linkPath='/blog/' sm>
                    <BlogIcon
                      style={{
                        margin: `0 .5rem 0  0`,
                        width: `1.25rem`
                      }}
                    />
                    <span lang='en'> HCRA Blog </span>
                  </Button>
                </div>
              </div>
            </Col>

            <button
              aria-label='Scroll to the top of the page'
              type='button'
              className={componentStyles.scrollBtn}
              onClick={scrollToTop}
            >
              <FaAngleUp />
            </button>
          </Row>
        </Container>
      </div>

      <nav className={componentStyles.footerBottom}>
        <Container>
          <Row>
            <Col>
              <ul>
                <li>
                  <Link to='/sitemap/'>
                    <FormattedMessage id='footer.sitemap' />
                  </Link>
                </li>
                <li>
                  <Link to='/terms-of-use/'>
                    <FormattedMessage id='footer.terms' />
                  </Link>
                </li>
                <li>
                  <Link to='/glossary/'>
                    <FormattedMessage id='footer.glossary' />
                  </Link>
                </li>
                <li>
                  {/* <Link to="/careers/">Careers</Link> */}
                  <a
                    href='https://hcraontario.bamboohr.com/jobs/?source=aWQ9Ng=='
                    // href='https://hcraontario.ca/job-postings.html'
                    aria-label={intl.formatMessage({ id: 'footer.careers' })}
                  >
                    <FormattedMessage id='footer.careers' />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </nav>
    </footer>
  )
}

export default PageFooter
